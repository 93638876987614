<template lang="">
    <div class="ckd_exchange">
        <div class="ckd_exchange-box">
          <p class="ckd_exchange-txt1">CDK加速时长兑换</p>
        <p class="ckd_exchange-txt2">银行级专线 覆盖全球千万用户</p>
        <div class="ckd_exchange-btnbox">
            <span class="ckd_exchange-btn" @click="changeTab('leigod')" :class="status == 'leigod' ? 'active' : ''">兑换雷神CDK</span>
            <span class="ckd_exchange-btn" @click="changeTab('nn')" :class="status == 'nn' ? 'active' : ''">兑换NN CDK</span>
        </div>
        <div class="ckd_exchange-leigodbox" v-if="status == 'leigod'">
        <!-- 请输入口令 -->
        <el-input v-model="cdk_str" placeholder="请输入雷神CDK" class="leigodinput" clearable></el-input>
        <el-input v-model="phone_number" placeholder="请输入手机号"  class="leigodinput" clearable>
            <el-select v-model="countryCode" placeholder="+86"  
            slot="prepend"
            :popper-append-to-body="false"
            class="input-with-select"
            @visible-change="changeSelect"
            >
                <el-input v-model="countryKeywords" 
                placeholder="搜索区号或国家关键字"
                :popper-append-to-body="false"
                prefix-icon="el-icon-search"
                class="leigodinput-search"
                >
              </el-input>
          
              <el-option
              v-for="(country, index) in countryCodeListCom"
              :key="index"
              :value="country.code"
              :label="'+' + country.code"
              v-if="countryCodeListCom.length"
            >
              <div class="cdk_country" >
                <img :src="country.ico" alt="">
              <div class="cdk_country_txt"> {{ country.name }}</div>
              <div> {{ "+" + country.code }}</div>
              </div>
             
            </el-option>
            <div slot='empty' class="ckd_nodata_box">
              <el-input v-model="countryKeywords" 
                placeholder="搜索区号或国家关键字"
                :popper-append-to-body="false"
                prefix-icon="el-icon-search"
                class="leigodinput-search"
                >
              </el-input>
              <div class="ckd_nodata" style="text-align:center;height:290px;line-height:290px">暂无数据</div>
            </div>
            </el-select>
        </el-input>
        <div class="ckd_exchange-leigodbox-btn" @click="exchangeCdk">立即兑换</div>
        </div>
        <div class="ckd_exchange-leigodbox"  v-if="status == 'nn'">
        <!-- 请输入口令 -->
        <el-input v-model="cdk_str" placeholder="请输入NN CDK" class="leigodinput" clearable></el-input>
        <el-input v-model="phone_number" placeholder="请输入手机号"  class="leigodinput" clearable>
            <el-select v-model="countryCode" placeholder="+86"  
            slot="prepend"
            :popper-append-to-body="false"
            class="input-with-select"
            >
                <el-input v-model="countryKeywords" 
                placeholder="搜索区号或国家关键字"
                :popper-append-to-body="false"
                prefix-icon="el-icon-search"
                class="leigodinput-search"
                >
                
              </el-input>
          
                <el-option
                v-for="(country, index) in countryCodeListCom"
                :key="index"
                :value="country.code"
                :label="'+' + country.code"
                >
                <div class="cdk_country">
                <img :src="country.ico" alt="">
              <div class="cdk_country_txt"> {{ country.name }}</div>
              <div> {{ "+" + country.code }}</div>
              </div>
                </el-option>
                <div slot='empty' class="ckd_nodata_box">
              <el-input v-model="countryKeywords" 
                placeholder="搜索区号或国家关键字"
                :popper-append-to-body="false"
                prefix-icon="el-icon-search"
                class="leigodinput-search"
                >
              
              </el-input>
              <div class="ckd_nodata" style="text-align:center;height:290px;line-height:290px">暂无数据</div>
            </div>
            </el-select>
        </el-input>
        <div class="ckd_exchange-leigodbox-btn" @click="exchangeCdk">立即兑换</div>
        </div>
        <div class="ckd_exchange-txtbox">
           <div>
            <p class="ckd_exchange-txtbox-txt1">CDK兑换说明：</p>
            <p class="ckd_exchange-txtbox-txt1">1、兑换前需输入正确的手机号，请核对号码后再点击兑换，加速时长将直接充到对应账号下；</p>
            <p class="ckd_exchange-txtbox-txt1">2、本网站仅支持已注册用户兑换，未注册用户请先下载客户端进行注册;</p>
           </div>
           <div class="ckd_exchange-txtbox-btn" v-if="status == 'leigod'" @click="download" v-show="ckd_isHome">
            <img src="@/assets/img/cdk_leigodbt.svg" alt="">下载雷神客户端
           </div>
           <div class="ckd_exchange-txtbox-btn" v-if="status == 'nn'" v-show="ckd_isHome" @click="downloadNN">
            <img src="@/assets/img/cdk_nn.png" alt="" >下载NN客户端
           </div>
        </div>
        <Geetest ref="Geetest" />
        </div>
    </div>
</template>
<script>
import { mixins } from "@/assets/js/minxin";
import Geetest from "@/components/common/Geetest";
export default {
  name: "Cdk_exchange",
  data() {
    return {
      inputText: "",
      countryKeywords: "",
      countryCode: 86,
      phone_number: "",
      cdk_str: "",
      status: "leigod",
      countryCodeList: [],
      // 控制渠道按钮展示与否
      ckd_isHome: 1,
    };
  },
  mixins: [mixins],
  components: {
    Geetest,
  },
  created() {
    this.getContryCode();
    this.ControlsBtn();
    console.log("111111111");
  },
  methods: {
    changeTab(type) {
      this.status = type;
      // 切换tab栏清空数据
      this.cdk_str = "";
      // this.countryCode=''
      this.phone_number = "";
    },
    /**
     * 获取手机区号
     */
    getContryCode() {
      if (this.countryCodeList.length) return;
      this.$Http
        .get("https://geoip.leigod.com/geoip2/leigod_country_codes.json")
        .then(({ data: { data } }) => {
          console.log("data", data);
          let { top_country = [], list_country = [] } = data;
          console.log("top_country", top_country, list_country);
          const topCods = top_country.map((item) => item.code);
          let num = 0;
          list_country.slice().forEach((item, index) => {
            if (topCods.some((code) => code === item.code)) {
              list_country.splice(index - num, 1);
              num++;
            }
          });
          this.countryCodeList = [].concat(top_country, list_country);
          console.log("this.countryCodeList", this.countryCodeList);
        });
    },

    async exchangeCdk() {
      if (!this.cdk_str.length) {
        this.$message.error("请输入CDK");
      } else if (!this.phone_number.length) {
        this.$message.error("请输入手机号！！");
      } else {
        let phoneReg = /^1[3-9]\d{9}$/;
        if (!phoneReg.test(this.phone_number)) {
          this.$message.error("请输入正确的手机号");
        } else {
          //极验证
          var Geetest = this.$refs.Geetest;
          if (!Geetest.ready) return;
          let Geetestdata = await Geetest.show().catch(() => null);
          if (Geetestdata == null) return;
          try {
            this.$Http
              .post(this.baseurl, {
                ...Geetestdata,
                country_code: this.countryCode,
                mobile_num: this.phone_number,
                cd_key: this.cdk_str,
              })
              .then(({ data }) => {
                if (data.code === 0) {
                  this.$message.success("兑换成功");
                } else {
                  this.$message.error(data.msg);
                }
              });
          } catch (error) {
            this.$message.error(error);
          }
        }
      }
    },
    changeSelect() {
      this.countryKeywords = "";
    },
    download() {
      this.setting().then(({ data: { data } }) => {
        let url = data[0].value;
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        // link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    downloadNN() {
      console.log("1");
      this.$Http
        .post(
          "/nn-version/channel/packInfo",
          {
            appId: "nnPcClient_1ntdFrM4",
            channel: "cdk_owe",
            platform: "3",
          },
          {
            headers: {
              reqChannel: 4,
            },
          }
        )
        .then(({ data: { retData } }) => {
          let url = retData.url;
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },

    //控制渠道按钮是否展示
    ControlsBtn() {
      this.setting().then(({ data: { data } }) => {
        this.ckd_isHome = data[1].value;
      });
    },
  },

  computed: {
    countryCodeListCom() {
      return this.countryCodeList.filter((item) => {
        // if(typeof this.countryKeywords == number)
        // console.log("item",typeof this.countryKeywords);
        return (
          item.name.indexOf(this.countryKeywords) !== -1 ||
          item.code.toString().indexOf(this.countryKeywords) !== -1
        );
      });
    },
    baseurl() {
      return this.status == "leigod"
        ? "api/card/cdkey/mobile"
        : "api/card/cdkey/mobile/nn";
    },
  },
};
</script>
<style lang="less" scoped>
.ckd_exchange {
  width: 100%;
  height: 100%;
  // height: calc(100vh - 127px);
  background-color: #000;
  background-image: url("@/assets/img/cdk_exbg.png");
  background-repeat: no-repeat;
  background-size: 2560px 1560px;
  background-position: center;
  box-sizing: border-box;
  margin-top: -1px;
  &-nodata {
    text-align: center;
  }
  &-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
  }

  &-txt1 {
    margin: 0;
    padding: 89px 0 17px 0;
    color: #fff;
    text-align: center;
    font-family: MiSans;
    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &-txt2 {
    margin: 0 0 111px 0;
    color: #fff;
    text-align: center;
    font-family: MiSans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }

  &-btnbox {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }

  &-btn {
    display: inline-block;
    width: 141px;
    height: 47px;
    border-radius: 4px;
    border: 1px solid #444;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-family: MiSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px;
    margin-right: 30px;
    cursor: pointer;
  }

  &-btn:hover {
    color: #fff;
    text-align: center;
    font-family: MiSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px;
    border-radius: 4px;
    border: 1px solid #888;
    background: #262626;
  }

  &-btn.active {
    border-radius: 4px;
    border: 1px solid #62cb9b;
    color: #62cb9b;
    text-align: center;
    font-family: MiSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px;
    margin-right: 30px;
  }

  &-leigodbox {
    width: 532px;
    margin: 0 auto;

    /deep/ .el-select-dropdown {
      left: -2px !important;
      width: 534px;
      height: 340px;
      border-radius: 4px;
      background: #2c2c2c;
      border-color: transparent;
      .ckd_nodata_box {
        .cdk_nodata {
          text-align: center;
          border: 1px solid red !important;
        }
      }

      .el-select-dropdown__wrap {
        max-height: 348px;
      }

      .popper__arrow {
        border-bottom-color: transparent !important;
        border-style: none !important;
      }

      .popper__arrow::after {
        border-bottom-color: transparent !important;
        border-style: none !important;
      }

      .el-select .el-input.is-focus .el-input__inner {
        border-color: transparent !important;
      }

      .el-select-dropdown__item.hover,
      .el-select-dropdown__item:hover {
        background: #333;
        height: 50px;
      }

      .el-select-dropdown__item {
        height: 50px;
        color: #fff;
        font-family: MiSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;

        .cdk_country {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          line-height: 50px;

          img {
            margin-right: 19px;
          }

          .cdk_country_txt {
            flex: 2;
            // margin-right: 415px;
          }
        }
      }

      .el-scrollbar__thumb {
        display: none;
      }

      .el-input {
        width: 532px;
        height: 50px;
        margin-bottom: 0;
        border-bottom: 1px solid #000;
        box-sizing: border-box;

        .el-input__icon {
          width: 40px;
          height: 50px;
        }

        .el-input__inner {
          display: flex;
          align-items: center;
          padding: 0 40px;
          height: 50px;
        }

        .el-input__inner:focus {
          border-color: transparent;
          box-shadow: none;
        }
      }
    }

    &-btn {
      width: 532px;
      height: 60px;
      border-radius: 6px;
      background: linear-gradient(115deg, #a1ffd6 0%, #36d490 100%);
      color: #000;
      text-align: center;
      font-family: MiSans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      cursor: pointer;
    }

    &-btn:hover {
      border-radius: 6px;
      background: linear-gradient(115deg, #66dfab 0%, #29b87a 100%);
    }

    /deep/ .leigodinput {
      width: 532px;
      height: 60px;
      border-radius: 4px;
      margin-bottom: 30px;

      .el-input__inner {
        // width: 100%;
        height: 60px;
        background: rgba(44, 44, 44, 0.8);
        border-color: transparent;
        color: #fff;
        font-family: MiSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .el-input__inner::placeholder {
        color: #646464;
        font-family: MiSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .el-input__clear {
          font-size: 25px !important;
        }
      }
      .el-input__inner:focus {
        border-radius: 4px;
        border: 1px solid #5ed9a4;
        background: rgba(44, 44, 44, 0.8);
        box-shadow: 0px 0px 10px 1px #62cb9b;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          position: relative;
          padding-top: 15px;
          .el-input__clear::before {
            display: inline-block;
            content: "";
            width: 26.25px;
            height: 26.25px;
            background: url("@/assets/img/cdk_delbtn.svg");
            font-size: 20px !important;
            color: rgba(130, 128, 128, 0.8);
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
      }
      .el-input__suffix:hover {
        .el-input__clear::before {
          background: url("@/assets/img/cdk_delbtnhover.svg");
        }
      }

      .el-input-group__prepend {
        background: rgba(44, 44, 44, 0.8);
        border-color: transparent;

        .el-input__inner {
          width: 95px;
          color: #fff;
          font-family: MiSans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          /deep/ .el-input__clear {
            font-size: 25px !important;
          }
        }
      }

      &-search {
        .el-input__inner {
          width: 100% !important;
          height: 100%;
          border-color: transparent !important;
        }

        .el-input__inner:focus {
          border-color: transparent !important;
          box-shadow: none !important;
        }
      }
    }
  }

  &-txtbox div:nth-child(1) {
    margin-right: 375px;
  }

  &-txtbox {
    display: flex;
    align-items: end;
    margin-top: 298px;
    justify-content: center;
    padding-bottom: 100px;
    &-txt1:nth-child(1) {
      margin-bottom: 18px;
    }

    &-txt1 {
      margin: 0;
      color: rgba(255, 255, 255, 0.6);
      font-family: MiSans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 185.714% */
    }

    &-btn:hover {
      border-radius: 44px;
      border: 1px solid #686868;
      background: #252525;
    }

    &-btn {
      width: 240px;
      height: 60px;
      border-radius: 44px;
      border: 1px solid #686868;
      color: rgba(255, 255, 255, 0.7);
      font-family: MiSans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 60px;
      text-align: center;
      cursor: pointer;

      img {
        vertical-align: middle;
        margin-right: 19.5px;
      }
    }
  }
}
</style>