export const mixins = {
    methods: {
       setting(){
         return   this.$Http.post("/tools/setting/list",{
            key_name: 'cdk_getchannelization,cdk_Ishome' ,
            lang: "zh_CN",
            region_code: 1,
        })
       }
    }
}