<template lang="">
    <div class="cdk_body">
       <div class="cdk_body-box">
        <p class="cdk_title">银行级专线 覆盖全球千万用户</p>
        <p class="cdk_title1">按分钟计费 | 随时可暂停加速时间</p>
        <div class="cdk_btn-box">
            <span class="cdk_btn" @click="download">
                <img src="@/assets/img/cdk_leigod.svg" alt="" >
                雷神客户端
            </span>
            <a class="cdk_btn" v-cloak 
            :download="nndownload.appName"  @click=downloadNN>
                <img src="@/assets/img/ckd_nn.svg" alt="" >
                NN客户端
            </a>
        </div>
        <div class="cdk_lgbox">
            <!-- <img :src="imgUrl" alt=""  ref="lightning"> -->
            <!-- <div v-cloak v-once key="lightning" ref="lightning" style="width: 174px;height: 256px;background-size:contain;">
              <img :src="imgUrl" width="100%" height="100%"  />
            </div> -->
            <img src="@/assets/img/cdk_lightning.png" alt="">
        </div>
        <div class="cdk_txtbox">
            <span class="cdk_txtbox1">
                <img src="@/assets/img/ckd_specialLine.png" alt="">
                <p class="cdk_txtbox1-txt1">全程使用金融级内网传输专线</p>
                <p class="cdk_txtbox1-txt2">游戏联网对战超低延时</p>
                <p class="cdk_txtbox1-txt2">自研驱动先进技术加速更稳定</p>
                <p class="cdk_txtbox1-txt2">上百人加速网络技术研发团队</p>
            </span>
            <span class="cdk_txtbox1">
                <img src="@/assets/img/ckd_package.svg" alt="">
                <p class="cdk_txtbox1-txt1">可随时暂停时长</p>
                <p class="cdk_txtbox1-txt2">用户充值时长不用可长期暂停 , 不过期</p>
                <p class="cdk_txtbox1-txt2">一次购买可以按需长久使用</p>
                <p class="cdk_txtbox1-txt2">注重极高品质 体贴用户需求 超划算</p>
            </span>
            <span class="cdk_txtbox1">
                <img src="@/assets/img/cdk_over.svg" alt="">
                <p class="cdk_txtbox1-txt1">全球千万用户覆盖</p>
                <p class="cdk_txtbox1-txt2">全球用户已超千万量级</p>
                <p class="cdk_txtbox1-txt2">服务器集群全球范围上万台覆盖</p>
                <p class="cdk_txtbox1-txt2">网络加速行业领军品牌软件产品</p>
            </span>
        </div>
       </div>
    </div>
</template>
<script>
export default {
  name: "Cdk_page",
  data() {
    return {};
  },
  mounted() {
    // console.log('mounted')
    // this.times = performance.now()
    // this.change().then(this.rfaStqate)
    // this.downloadNN()
  },
  data() {
    return {
      nndownload: "",
      imgs: [],
      count: 0,
      times: 0,
    };
  },
  methods: {
    // async change() {
    //   const imgs = () => new Array(119).fill(1).map((i, count) => () => require(`@/assets/img/lightning/Component_25_${(100000 + count).toString().slice(1)}.png`))
    //   for(const imgPromise of await Promise.all(imgs())) {
    //     this.imgs.push(imgPromise())
    //   }
    //   return await this.$nextTick()
    // },
    // rfaStqate() {
    //   // if(performance.now() - this.times > 10 && this.$refs.lightning) {
    //   if(this.$refs.lightning) {
    //       this.times = performance.now()
    //       if(this.count < this.imgs.length) {
    //         this.$refs.lightning.style.backgroundImage = `url(${this.imgs[this.count]})`
    //         this.count = this.count + 1;
    //       } else {
    //         this.count = 0
    //         this.$refs.lightning.style.backgroundImage = `url(${this.imgs[this.count]})`
    //       }
    //       window.requestAnimationFrame(this.rfaStqate)
    //     } else {
    //       window.requestAnimationFrame(this.rfaStqate)
    //     }
    // },
    download() {
      this.setting().then(({ data: { data } }) => {
        let url = data[0].value;
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    downloadNN() {
      this.$Http
        .post(
          "/nn-version/channel/packInfo",
          {
            appId: "nnPcClient_1ntdFrM4",
            channel: "cdk_owe",
            platform: "3",
          },
          {
            headers: {
              reqChannel: 4,
            },
          }
        )
        .then(({ data: { retData } }) => {
          console.log(111,retData)
          let {url} = retData;
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.cdk_body {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  // height: calc(100vh - 127px);
  background-image: url(@/assets/img/cdk_bg.png);
  background-repeat: no-repeat;
  background-size: 2560 1560px;
  background-position: center center;
  box-sizing: border-box;
  margin-top: -1px;
  background-color: black;
  &-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
}
.cdk_title {
  margin: 0;
  padding: 88px 0 17px 0;
  color: #fff;
  text-align: center;
  font-family: MiSans;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}
.cdk_title1 {
  margin: 0;
  color: #fff;
  text-align: center;
  font-family: MiSans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
.cdk_btn-box {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.cdk_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 60px;
  border-radius: 6px;
  margin-right: 40px;
  background: linear-gradient(115deg, #a1ffd6 0%, #36d490 100%);
  cursor: pointer;
  color: black;
  &:link {
    color: black;
  }
  img {
    margin-right: 14px;
  }
}
.cdk_btn:hover {
  border-radius: 6px;
  background: linear-gradient(115deg, #66dfab 0%, #29b87a 100%);
}

.cdk_lgbox {
  display: flex;
  justify-content: center;
  margin-top: 88px;
  img {
    width: 174px;
    height: 256px;
    object-fit: contain;
    object-position: center;
  }
}
.cdk_txtbox {
  display: flex;
  justify-content: center;
  margin-top: 89px;
  padding-bottom: 127px; ;
}
.cdk_txtbox1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 262px;
  height: 314px;
  border-radius: 20px;
  border: 1px solid #2e2e2e;
  background: rgba(46, 46, 46, 0.6);
  margin-right: 40px;
  border-radius: 20px;
  border: 1px solid #2e2e2e;
  background: rgba(46, 46, 46, 0.6);
  backdrop-filter: blur(8.5px);
  img {
    margin: 53.5px 0 56.5px 0;
  }
  &-txt1 {
    margin: 0 0 22px 0;
    color: #fff;
    // text-align: center;
    font-family: MiSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
  }
  &-txt2 {
    margin: 5px;
    color: rgba(255, 255, 255, 0.4);
    // text-align: center;
    font-family: MiSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1; /* 171.429% */
  }
}
.cdk_txtbox1:hover {
  position: relative;
  top: -5px;
  box-shadow: 0 18px 18px rgba(0, 0, 0, 0.5);
}
</style>