import axios from 'axios'
// import { useStore } from '@/stores/store.js'
// import router from '@/router/index.js'
// import { ElMessage } from 'element-plus'
// import pinia from '@/stores/pinia.js'
// const store = useStore();
const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,// 所有请求的公共地址部分
  timeout: 100000, // 请求超时时间 这里的意思是当请求时间超过5秒还未取得结果时 提示用户请求超时
  // headers: {
  //   // appId: 'leigodInternational_6i5Vll5b',
  //   // reqChannel: 4,
  // },
})
// 添加请求拦截器
api.interceptors.request.use(function (config) {
  if( config.data?.platform == "3"){
    config.baseURL = process.env.VUE_APP_NN_API_URL
  }else{
    config.baseURL = process.env.VUE_APP_API_URL
  }
  // config.platform 
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});


export default api
