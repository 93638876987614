import Vue from 'vue'
import VueRouter from 'vue-router'
import Ckd_Wb from '@/views/Cdk_Wb'
import Cdk_page from  '@/components/Cdk_page'
import Cdk_exchange from '@/components/Cdk_exchange'
import ServiceAgreement from '@/components/ServiceAgreement.vue'
import privacyPolicy from '@/components/privacyPolicy.vue'
// 引入全局样式
import '@/assets/css/global.less'

Vue.use(VueRouter)

const routes = [

  {
    path:'/',
    component:Ckd_Wb,
    redirect:'/Cdk_page',
    meta: {
      title: "网游加速器-银行及专线，覆盖全球千万用户",
      cdk_ishome:1
    },
    children:[
      {
      path:'/Cdk_page',
      component:Cdk_page,
      meta: {
        title: "网游加速器-银行及专线，覆盖全球千万用户",
        cdk_ishome:1
      },
    },{
      path:'/Cdk_exchange',
      component:Cdk_exchange,
      meta: {
        title: "网游加速器-银行及专线，覆盖全球千万用户",
      },
    },{
      path:'/ServiceAgreement',
      component:ServiceAgreement,
      meta: {
        title: "网游加速器-银行及专线，覆盖全球千万用户",
      },
    },
    {
      path:'/privacyPolicy',
      component:privacyPolicy,
      meta: {
        title: "网游加速器-银行及专线，覆盖全球千万用户",
      },
    }
  
  ]

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes
})
// console.log("router",router.__proto__.push);
// 修改原型对象中的push函数
// router.__proto__.push.push = function push(location){
//   return originalPush.call(this , location).catch(err=>err)
//   }




export default router
