<template lang="">
    <div class="cdk_privacyPolicy">
        <div class="cdk_privacyPolicy-box"><
            <p class="cdk_privacyPolicy-title">隐私政策</p>
            <div class="cdk_privacyPolicy-txtbox">
                <p class="cdk_privacyPolicy-txt1">本《隐私政策》（以下称“本政策”）适用于由【喀什新巢电子科技服务有限公司】（以下称“我们”）为您提供的软件下载及其他服务（以下通称“产品”）。</p>
                <p class="cdk_privacyPolicy-txt1 textDec">我们重视您的隐私保护，并深知个人信息对您的重要性。我们将通过本政策向您说明该产品将会如何收集、使用和存储您的个人信息、我们会如何保护您的个人信息以及您享有何种权利。<i>请您在使用产品前，仔细阅读并了解本政策。重点内容我们已经以粗字体加下划线表示，希望您特别关注。一旦您开始使用本产品，即表示您同意我们按照本政策处理您的相关信息。</i> </p>
                <p class="cdk_privacyPolicy-txt1">本政策将帮助您了解以下内容：</p>
                <p class="cdk_privacyPolicy-txt1">一、我们如何收集和使用您的个人信息</p>
                <p class="cdk_privacyPolicy-txt1">二、我们如何共享、转让、公开披露您的个人信息</p>
                <p class="cdk_privacyPolicy-txt1">三、我们如何存储您的个人信息</p>
                <p class="cdk_privacyPolicy-txt1">四、我们如何保护您的个人信息</p>
                <p class="cdk_privacyPolicy-txt1">五、我们如何使用Cookie和同类技术</p>
                <p class="cdk_privacyPolicy-txt1">六、我们如何处理未成年人的个人信息</p>
                <p class="cdk_privacyPolicy-txt1">七、您如何行使管理个人信息的权利</p>
                <p class="cdk_privacyPolicy-txt1">八、本政策如何更新</p>
                <p class="cdk_privacyPolicy-txt1">九、如何联系我们</p>
                <p class="cdk_privacyPolicy-txt1 textDec">需要特别说明的是<i>，本政策不适用于其他第三方向您提供的软件、产品或服务。若您使用的软件、产品或服务由第三方提供的，请您自行阅读并遵守第三方的隐私政策。</i></p>
                <h4>一、我们如何收集和使用您的个人信息</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：</p>
                <h4>(一)为向您提供产品核心功能须收集和使用您个人信息的情形</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">为实现本产品的核心业务功能，我们可能需要向您收集个人信息。以下将详细列出本产品的核心业务功能及为实现该功能所需收集的个人信息，若您拒绝收集，则无法使用本产品。</p>
                <table>
                    <tr>
                        <th>核心业务功能</th>
                        <th>功能说明</th>
                        <th>功能说明</th>
                    </tr>
                    <tr>
                        <td>浏览/搜索/下载/安装/卸载应用</td>
                        <td>我们收集您的信息来保障您能正常使用我们浏览/搜索/下载/安装/卸载应用的服务和功能。</td>
                        <td>操作系统，操作系统版本，mac，sn，主板，机型，软件安装，软件卸载，软件安装列表，前置窗口</td>
                    </tr>
                    <tr>
                        <td>CDK兑换</td>
                        <td>我们收集您的信息来向您提供CDK兑换服务。</td>
                        <td>您的手机号信息、第三方账户信息等，我们将通过发送短信验证的方式来进行手机号码认证，以便提供相关服务</td>
                    </tr>
                </table>
                <h4>(二)为向您提供产品附加功能须收集和使用您个人信息的情形</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">为了向您提供更优质的产品和服务，后续我们可能对部分功能进行完善，可能需要收集下述信息。如果您拒绝提供下述信息，不影响您正常使用第(一)项所描述的产品核心业务功能，但我们无法向您提供某些特定功能和服务。</p>
                <table >
                    <tr>
                        <th>附加业务功能</th>
                        <th>功能说明</th>
                        <th>需要收集的个人信息</th>
                    </tr>
                    <tr>
                        <td>用户账户注册</td>
                        <td>在使用本产品过程中可能需要依照账户注册流程注册一个您个人的账户</td>
                        <td>您的手机号码、邮箱、密码、拟使用的账户名。我们将通过发送短信验证的方式来进行手机号码认证，以验证您的身份是否有效。</td>
                    </tr>
                </table>
                <h4>(三)我们从第三方间接获得您个人信息的情形</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">我们可能从第三方获取您授权共享的账户信息（昵称、头像），使您可以通过第三方账户直接登录并使用我们的产品或服务，为此我们需要您同意本政策并将您的第三方账户与本产品账户进行绑定。我们仅会在您的授权同意的范围内收集并使用您的个人信息。</p>
                <h4>(四)征得授权同意收集、使用您个人信息的例外</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">您充分了解，根据相关法律法规的规定，<i>在以下情形中，我们收集、使用个人信息无需征得您的授权同意：</i></p>
                <h4>(四)征得授权同意收集、使用您个人信息的例外</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">1)与国家安全、国防安全直接相关的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">2)与公共安全、公共卫生、重大公共利益直接相关的;</p>
                <p class="cdk_privacyPolicy-txt1 textDec">3)与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">4)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">5)所收集的个人信息是您自行向社会公众公开的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">6)从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">7)根据您的要求签订和履行合同所必需的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec"> 8)用于维护本产品和相关服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">9)法律法规规定的其他情形。</p>
                <h4>(五)个人信息的使用规则</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">1.我们会根据本政策的约定对所收集的个人信息进行使用。</p>
                <p class="cdk_privacyPolicy-txt1 textDec">2.您根据本政策所提供的或同意我们收集的您的个人信息，将在您使用本产品期间持续授权我们使用。在您注销账号并通知我们时，我们停止使用并删除您的个人信息或进行匿名化处理。</p>
                <p  class="cdk_privacyPolicy-txt1 textDec"><i>3.为改善我们的产品，以便向您提供更符合您个性化需求的服务，我们会根据您的浏览记录、设备信息、位置信息等，提取您的偏好、行为习惯等特征，基于特征标签进行间接人群画像以提供更加精准和个性化的服务和内容，并展示、推送信息和可能的商业广告。如果您不想接收我们给您发送的商业广告，您可以通过短信提示回复退订或采用我们提供的其他方式进行退订。</i></p>
                <p  class="cdk_privacyPolicy-txt1 textDec"><i>4.当我们将您的个人信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</i></p>
                <h4>二、我们如何共享、转让、公开披露您的个人信息</h4>
                <p class="cdk_privacyPolicy-txt1 textDec"><i>(一)共享我们不会与除关联方以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</i></p>
                <p class="cdk_privacyPolicy-txt1 textDec">1.在事先获取您明确同意或授权的情况下共享；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">2.根据适用的法律法规、法律程序的要求、政府主管部门的强制性要求或司法裁决而需要共享；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">3.在法律法规要求或允许的范围内，为了维护我们、您及其他用户或社会公众的利益、财产或安全免遭损害而有必要提供；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">4.只有共享您的信息，才能实现我们的产品功能或向您提供您需要的服务；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">5.与我们的关联公司共享：您的个人信息可能会在我们的关联公司之间共享。我们只会共享必要的个人信息，且这种共享受到本政策所声明目的的约束。我们的关联公司如要改变个人信息的处理目的，将再次征求您的授权同意；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">6.与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以保障为您提供的服务顺利完成。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。同时，我们会与合作伙伴签署严格的保密协定，要求他们按照本政策以及其他任何相关的保密和安全措施来处理您的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</p>
                <p class="cdk_privacyPolicy-txt1 textDec"><i>(二)转让我们不会将您的个人信息转让给与除关联方以外任何公司、组织和个人，但以下情况除外：</i></p>
                <p class="cdk_privacyPolicy-txt1 textDec">1.事先获得您明确的同意或授权；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">2.根据适用的法律法规、法律程序的要求、政府主管部门的强制性要求或司法裁决所必须的情况进行转让；</p>
                <p  class="cdk_privacyPolicy-txt1 textDec"><i>3.在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</i></p>
                <h4>(三)公开披露</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">我们仅会在以下情况下，公开披露您的个人信息：</p>
                <p class="cdk_privacyPolicy-txt1 textDec">1.事先获得您明确的同意或授权；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">2.根据适用的法律法规、法律程序的要求、政府主管部门的强制性要求或司法裁决所必须的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在上述情况发生时，我们会要求披露请求方必须出具与之相应的有效法律文件，并对被披露的信息采取符合法律和业界标准的安全防护措施。</p>
                <h4>(四)共享、转让、公开披露个人信息时事先征得授权同意的例外</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">根据相关法律法规的规定，<i>在以下情形中，我们可以在不征得您的授权同意的情况下共享、转让、公开披露您的个人信息：</i></p>
                <p class="cdk_privacyPolicy-txt1 textDec">1.与国家安全、国防安全有关的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">2.与公共安全、公共卫生、重大公共利益有关的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">3.与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">5.您自行向社会公众公开的个人信息；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">6.从合法公开披露的信息中收集到的个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
                <p class="cdk_privacyPolicy-txt1 textDec"><i>根据法律规定，若我们对个人信息采取技术措施和其他必要措施进行去标识化处理，且确保数据接收方无法复原并重新识别个人信息主体的，对此类数据的共享、转让、公开披露将无需另行向您通知并征得您的同意。</i></p>
                <h4>三、我们如何存储您的个人信息</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">1.存储地点</p>
                <p class="cdk_privacyPolicy-txt1 textDec">按照法律法规的要求，我们将在中华人民共和国境内收集和产生的个人信息，存储在中华人民共和国境内。</p>
                <p class="cdk_privacyPolicy-txt1 textDec">2.存储时间</p>
                <p class="cdk_privacyPolicy-txt1 textDec">我们将只在您的帐户有效期内或者在向您提供产品相关服务和技术支持所需的期限内保留您的信息。我们还将在为遵守适用的法律义务、解决争议以及执行协议而必要的期限内保留您的个人信息。对于超出期限的个人信息，我们会立即删除或做匿名化处理。</p>
                <h4>四、我们如何保护您的个人信息</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在传输敏感信息时，我们使用加密来保护您信息的安全；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
                <p class="cdk_privacyPolicy-txt1 textDec">我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
                <p class="cdk_privacyPolicy-txt1 textDec"><i>互联网并非绝对安全的环境，没有任何产品、服务或数据传输可以保证100%安全。</i>如果不幸发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您；难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况</p>
                <h4>五、我们如何使用Cookie和同类技术</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">为帮助您获得更好的网站访问体验，我们会使用Cookie，以存储和帮助跟踪关于您的信息。Cookie是从网络服务器发送到您的浏览器并存储在您的计算机或移动设备上的小数据文件，通常包含标识符、站点名称以及一些号码和字符。借助Cookie，我们能够保存您的偏好等数据，使得您无需在每次访问时都要重新输入；同时，我们还可能利用上述技术获取的流量模式、访问模式等信息，来简化网站浏览、进行产品推荐以及帮助设计我们的网站以让您的体验更高效、更愉悦。</p>
                <p class="cdk_privacyPolicy-txt1 textDec">我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。大部分网络浏览器都设有阻止Cookie的功能，您可以通过浏览器设置选项清除计算机上保存的所有Cookie，或使用浏览器的无痕浏览设置来访问我们的网站，但如果您这么做，可能会导致则您需要在每一次访问我们的网站时都需要亲自更改用户设置。</p>
                <h4>六、我们如何处理未成年人的个人信息</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">我们非常重视对未成年人个人信息的保护。<i>如果您是未成年人，在使用本产品前，请您务必在监护人的陪同下阅读本政策，并确保已征得您的监护人明确同意后，方可使用本产品并向我们提供您的个人信息。对于经监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、监护人明确同意或者保护未成年人所必要的情况下使用或披露此信息。</i></p>
                <h4>七、您如何行使管理个人信息的权利</h4>
                <h4>(一)按照法律、法规及相关国家标准的要求，我们保障您对自己的个人信息行使以下权利：</h4>
                <h4>1.访问和更正您的个人信息</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">除法律法规另有规定外，您有权随时访问您的个人信息。当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。</p>
                <h4>2.删除您的个人信息</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">您可以删除您的地址、手机号码等个人信息。</p>
                <h4>在以下情形中，您可以向我们提出删除个人信息的请求：</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">1)如果我们处理个人信息的行为违反法律法规；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">2)如果我们收集、使用您的个人信息，却未征得您的同意；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">3)如果我们处理个人信息的行为违反了与您的约定；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">4)如果您不再使用我们的产品或服务，或您注销了账号；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">5)如果我们不再为您提供产品或服务。</p>
                <p class="cdk_privacyPolicy-txt1 textDec">若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。<i>当您从我们的产品中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</i></p>
                <h4>3.改变您授权同意的范围或撤回您的授权</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">每个业务功能需要一些基本的个人信息才能得以完成（见本政策第一条）。您可以通过删除信息、关闭特定功能、进行隐私设置等方式给予或撤回您的授权同意。<i>但请您理解，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</i></p>
                <h4>4.注销您的用户账户</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">如果您希望注销您的用户账户，请您向我们提出账户注销要求，我们将在验证您的身份并清理账户中的资产（如有）后，为您提供账号注销服务。<i>账户注销成功后，我们将停止为您提供产品或服务，并根据适用法律法规的要求删除您的个人信息或做匿名化处理。</i></p>
                <h4>(二)我们如何响应您的上述请求</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">我们会在收到您的请求并验证您的身份后的三十天内处理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份中信息）的请求，我们可能会予以拒绝。</p>
                <h4 class="cdk_privacyPolicy-txt1 textDec"><i>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</i></h4>
                <p class="cdk_privacyPolicy-txt1 textDec">1.与国家安全、国防安全直接相关的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">2.与公共安全、公共卫生、重大公共利益直接相关的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">3.与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">4.有充分证据表明您存在主观恶意或滥用权利的；</p>
                <p class="cdk_privacyPolicy-txt1 textDec">5.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
                <p class="cdk_privacyPolicy-txt1 textDec">6.涉及商业秘密的。</p>
                <p class="cdk_privacyPolicy-txt1 textDec">八、本政策如何更新</p>
                <p class="cdk_privacyPolicy-txt1 textDec"><i>我们保留根据业务调整、法律法规或政策的变化而不时更新或修改本政策的权利。如果我们对本政策作出任何重大变更，我们会在该等变更生效之前通过推送通知、公告、弹窗形式或者发送电子邮件、短信等方式告知您，以便您能及时了解该等变更。若您不同意修改后的本政策，您有权并应立即停止使用本产品。</i></p>
                <h4>九、其他</h4>
                <p class="cdk_privacyPolicy-txt1 textDec">我们的注册地址为：【新疆喀什地区喀什经济开发区兵团分区】。如果您对我们的回复不满意，特别是如果我们的个人信息处理行为损害了您的合法权益，您还可以<i>通过向我们所在地有管辖权的法院提起诉讼来寻求解决方案。</i></p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'privacyPolicy'
}
</script>
<style lang="less" scoped>
    .cdk_privacyPolicy {
    width: 100%;
    height: 1199px;
    background-image: url("@/assets/img/cdk_exbg.png");
    background-repeat: no-repeat;
     background-size: 2560px 1199px;
    &-box {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        overflow: auto;

        .textDec{
            i{
                text-decoration: underline;
                font-weight: 700;
                font-style: normal;
            }
  }
    }

  &-title {
    color: #fff;
    text-align: center;
    font-family: MiSans;
    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    padding: 80px 0 37px 0;
  }
  &-txtbox {
    width: 931px;
    height: 901px;

    margin: 0 auto;
    color: rgba(255, 255, 255, 0.6);
    font-family: MiSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
  }
  &-txt1 {
    color: rgba(255, 255, 255, 0.6);
    font-family: MiSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
  }

}
</style>