<template lang="">
    <div class="cdk_footer">
           <div class="cdk_footer-box">
                <!-- <div class="cdk_footer-txt" @click="gotoAgreement('/ServiceAgreement')">服务协议</div> -->
                <!-- <div class="cdk_footer-txt" @click="gotoAgreement('/privacyPolicy')">隐私政策</div> -->
                <router-link class="cdk_footer-txt" to="/ServiceAgreement" target="_blank">服务协议</router-link>
                <router-link class="cdk_footer-txt" to="/privacyPolicy" target="_blank">隐私政策</router-link>
                <span class="cdk_footer-txt">鄂ICP备2024052786号-1</span>
                <p class="cdk_footer-txt1">湖北新巢电子科技有限公司 版权所有</p>
                <p class="cdk_footer-txt1">公司地址：湖北省武汉市东湖新技术开发区山湖北路1号武汉软件新城4.1期F8-1、F8-2栋F8-1单元4层01厂房号29室</p>
           </div>
    </div>
</template>
<script>
export default {
    name:'Ckd_footer',
    
    methods:{
        // gotoAgreement(type){
        //     let routeData = this.$router.resolve({
        //     path:type,
		//     });
        // console.log("routeData.href",routeData.href);
		// window.open(routeData.href, '_blank');
        // //     this.$router.push({path:type},()=>{})
        // }
    }
}
</script>
<style lang="less" scoped>
    .cdk_footer{
       
        width: 100%;
        height: 64px;
        background: #262626;
        &-box{
            min-width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            line-height: 64px;
            justify-content: center;
        }
        &-txt{
            color: #737373;
            text-align: center;
            font-family: MiSans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
        }
        &-txt:hover{
            color: #FFF;
            text-align: center;
            font-family: MiSans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        &-txt::after{
            content: '|';
            display: inline-block;
            color: #737373;
            margin: 0 30px;

        }
        &-txt1{
            color: #737373;
            text-align: center;
            font-family: MiSans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &:not(:first-child){
                margin-right: 20px;
            }
        }
    }
</style>