<template lang="">
    <div class="cdk_ServiceAgreement">
        <div class="cdk_ServiceAgreement-box">
            <p class="cdk_ServiceAgreement-title">服务协议</p>
          <div class="cdk_ServiceAgreement-txtbox">
            <p class="cdk_ServiceAgreement-txt1">您使用本文件项下设备及相关软件（“软件”）及互联网及其他服务（“服务”），受本服务条款（以下称“本协议”）以及本协议援引的其他法律条款的约束。在下载、安装和使用软件和服务前，请务必仔细阅读本协议条款。“您”是指任何个人或单一法律实体。
“我们”是指本平台的运营主体，即喀什新巢电子科技服务有限公司。</p>
            <p class="cdk_ServiceAgreement-txt1">1. 本协议的结构</p>
            <p class="cdk_ServiceAgreement-txt1">本协议条款是通用条款，适用于本协议项下的所有软件和服务。</p>
            <p class="cdk_ServiceAgreement-txt1">除通用条款外，我们可能会针对特定软件或服务提供特别条款，则该特别条款将成为本协议的组成部分。如特别条款与通用条款不一致，则仅就该特定的软件或服务而言，以特别条款为准。</p>
            <p class="cdk_ServiceAgreement-txt1">某些软件或服务可能由第三方提供，就该第三方软件或服务的使用，受该第三方法律条款的约束</p>
            <p class="cdk_ServiceAgreement-txt1">2. 接受本协议</p>
            <p class="cdk_ServiceAgreement-txt1">2.1 您必须首先接受和同意本协议方可使用软件和服务。如您不同意本协议条款，请不要安装、下载或使用软件及服务。如您为购买软件或服务支付了费用，您可联系您的供货商以获得退款。</p>
            <p class="cdk_ServiceAgreement-txt1">2.2 您可通过以下方式接受本协议：（1）按任何软件或服务用户界面的提示，选择接受或同意本协议；（2）实际使用本协议项下软件或服务。</p>
            <p class="cdk_ServiceAgreement-txt1">2.3 您应确保您已年满18岁、是独立生活的未成年人或获得了父母或监护人的同意，并具有达成有法律约束力协议的完全行为能力。如果您未达到可以达成有法律约束力合同的法定成人年龄，您应确保本协议是由您的父母或监护人代表您签署确认的。如您代表他人（例如某个公司或您的客户）确认接受本协议，您声明并保证您已经获得了充分的授权并有资格这样做。</p>
            <p class="cdk_ServiceAgreement-txt1">3. 软件许可</p>
            <p class="cdk_ServiceAgreement-txt1">3.1 本协议项下软件由喀什新巢电子科技服务有限公司或其供应商拥有并受版权保护，仅为许可使用而非销售给您。我们将授予您一项个人的、全球范围的、免费的、不可转让的、非排他的和无分许可权的使用许可，使您可以本协议允许的方式使用软件。您可仅限于为支持该使用的目的制作和安装包括备份在内的副本。本协议适用于您制作的每一个副本。</p>
            <p class="cdk_ServiceAgreement-txt1">3.2 您不得复制、修改、分发、销售或出租软件或其任何部分，不得对软件制作派生作品、进行反向工程、反汇编、反编译、提取源代码或实施其他解译行为，除非相关法律禁止此类限制或您另外获得了我们的书面允许。您不得删除或更改软件的任何版权和所有权信息。</p>
            <p class="cdk_ServiceAgreement-txt1">3.3 您应确保使用软件的任何人都将遵守本协议。 </p>
            <p class="cdk_ServiceAgreement-txt1">3.4 本协议项下的某些软件或其组件可能基于随软件附带的开源许可证提供。开源许可证中的某些条款可能会取代本协议的某些条款。</p>
            <p class="cdk_ServiceAgreement-txt1">4. 账户注册</p> 
            <p class="cdk_ServiceAgreement-txt1">4.1 某些情况下，为使用软件或服务，您可能需要依照我们账户注册流程创立一个您个人的账户(“账户”)。某些软件或服务会需要您注册成为其用户，您知悉并授权我们为您直接注册而无需另行通知您。某些软件或服务允许您注册和使用我们或第三方账户（例如,我们通行证），此时您会受适用于该我们或第三方账户的法律条款的约束。</p>
            <p class="cdk_ServiceAgreement-txt1">4.2 在您的账户注册过程中以及在此后我们要求时，您同意向我们提供真实、准确、最新和完整的必要账户管理信息和数据（如您的姓名、电子邮件地址及电话号码等，统称为“注册信息”）。您应始终维护并随时及时更新您的注册信息。如果您提供的注册信息虚假、不正确、不完整或已经过时，可能会影响您对软件或服务的使用。</p>
            <p class="cdk_ServiceAgreement-txt1">5. 账户和密码安全</p>
            <p class="cdk_ServiceAgreement-txt1">5.1 您应自行负责妥善保管您的登录信息和密码。无论是否经过您的授权，对通过您账户进行的或在您账户中发生的所有活动，均应由您自行负责。</p>
            <p class="cdk_ServiceAgreement-txt1">5.2 如发现对您账户的任何未经授权的使用或发生其他此类安全问题，您应立即告知我们及服务提供商。对他人未经授权使用您的帐户，我们不承担任何责任。</p>
            <p class="cdk_ServiceAgreement-txt1">5.3 账户由确认和接受本协议的人拥有。如此后账户所有权发生变化，应有相关法律文件证明。我们不对与账户内部活动及账户所有权相关的争议进行判定。如不能确定账户的有效所有者，我们有权暂停或取消该账户。</p>
            <p class="cdk_ServiceAgreement-txt1">6. 使用政策</p>
            <p class="cdk_ServiceAgreement-txt1">6.1 在使用本协议项下软件或服务时，您应遵守我们告知的所有可适用的使用政策。</p>
            <p class="cdk_ServiceAgreement-txt1">6.2 您不得为任何非法、欺诈、不当或滥用的目的或以任何可能妨碍其他用户使用软件或服务，或损害我们或其他用户的任何财产，以及侵犯或妨害第三方权利的方式使用软件或服务。</p>
            <p class="cdk_ServiceAgreement-txt1">6.3 您对本协议项下服务的使用应遵循所有相关的国家、地方、国际和国内法律的规定。</p>
            <p class="cdk_ServiceAgreement-txt1">6.4 对因您使用本软件或服务以及您违反使用政策导致的损失、损害及其他后果，由您自行负责。您同意为我们及其供应商提供补偿、抗辩并确保其不因您的数据、您对软件或服务的使用或您违反本协议条款所导致的任何损失、成本、责任及其他费用而受到损害。</p>
            <p class="cdk_ServiceAgreement-txt1">7. 用户数据的存储</p>
            <p class="cdk_ServiceAgreement-txt1">7.1 某些服务允许您上传、存储、发送或接受某些您的数据，其中可能包含您的个人数据（“用户数据”）。您对这些用户数据所拥有的任何权利均由您保留，除非经您的事先同意和允许，否则我们不会自己或允许他人使用您的用户数据。</p>
            <p class="cdk_ServiceAgreement-txt1">7.2 您通过我们的服务上传、存储、传送或接收用户数据，视为您授予我们及其服务提供商一个全球范围的许可，使其可以作为服务的组成部分并仅为提供和改进服务的目的，复制并以加密方式存储您的数据。您应确保您拥有所有必要的权利和权力授予上述许可。</p>
            <p class="cdk_ServiceAgreement-txt1">7.3 为您的用户数据提供存储不是我们的义务，我们仅为用户方便的目的提供此项服务，为此，您知道并同意，我们将不对数据无论因任何原因被删除或存储失败承担责任。您应自行负责及时对您的用户数据做妥善的备份。您知道并同意，我们可能会对您上传或存储的用户数据的数量和期限设置限制。</p>
            <p class="cdk_ServiceAgreement-txt1">8. 数据收集、使用及隐私权保护</p>
            <p class="cdk_ServiceAgreement-txt1">8.1 为提升您的使用体验及改善我们的产品和服务，我们可能会收集您对软件和服务使用情况的一些基本信息。当您连接到互联网时，这些信息会发送到我们，这个过程可能会消耗微小的互联网流量，但不会对您的设备使用带来任何不便。这个过程不会收集任何可用于识别您个人身份的信息。</p>
            <p class="cdk_ServiceAgreement-txt1">8.2 您同意我们可以为履行本协议的目的，通过电子邮件或消息弹窗等方式向您发送一些公告、管理信息以及其他与软件或服务有关的技术服务和支持信息。</p>
            <p class="cdk_ServiceAgreement-txt1">8.3 若您注册我们账户或登录服务时，您可能会被要求提供注册信息，其中可能包含您的个人身份信息。您同意我们可以根据其隐私权政策，自行或允许其服务提供商为提供和改进软件或服务的目的而使用这些信息。您同意我们可以在符合相关法律规定的前提下，在您所在国家或地区之外存储和传输上述数据和信息。</p>
            <p class="cdk_ServiceAgreement-txt1">您可阅读我们的隐私政策,了解我们收集、使用、存储和处理您的注册信息及用户数据相关政策的详</p>
            <p class="cdk_ServiceAgreement-txt1">9. 软件和服务的更新</p>
            <p class="cdk_ServiceAgreement-txt1">9.1 您知悉并同意，我们并非软件或服务的直接服务提供商，您在使用软件或服务的过程中产生的任何问题我们均不承担任何责任。</p>
            <p class="cdk_ServiceAgreement-txt1">9.2 一旦软件或服务有新的版本或特征，您设备上的软件可能会被自动更新。您可通过调整设置停止软件或服务的自动更新。本协议条款适用于软件和服务的所有更新和补丁程序。</p>
            <p class="cdk_ServiceAgreement-txt1">9.3 您可随时停止使用我们的软件和服务。我们也可能会不时变更或改进我们的软件和服务，对某些软件和服务变更我们可能不会事先通知。</p>
            <p class="cdk_ServiceAgreement-txt1">9.4 我们保留经提前一（1）个月通知，随时中止或永久停止提供某项服务或其任何部分的权利。在上述期限内，我们将尽商业上合理的努力，使您能够下载可能存于我们服务中的数据。过了该期限之后，您的数据将被安全销毁。</p>
            <p class="cdk_ServiceAgreement-txt1">10. 服务费用</p>
            <p class="cdk_ServiceAgreement-txt1">使用某些服务可能需要您另外支付费用，具体按相关服务条款执行。我们保留对服务收取费用及改变收费标准和收费方式的权利。除以信用卡、借记卡或电子支付方式进行的交易外，费用应在收到发票时到期应付，您同意支付发票上指明的费用，包括任何可适用的税款和滞纳金。您自行负责支付与服务相关的税款。您在使用软件或服务的过程中产生的费用用服务提供商收取，与我们无关。</p>
            <p class="cdk_ServiceAgreement-txt1">11. 无保证声明</p>
            <p class="cdk_ServiceAgreement-txt1">11.1 除非我们另有明确规定，否则软件和服务均以“按现状”且“可用”为基础提供，无任何保证。在法律允许的最大限度内，我们明确否认任何包括但不限于有关适销性、适用于某个特定目的、权属以及不侵权在内的明示、默示及法定的保证以及任何有关安全、可靠性、及时性和软件及服务性能的保证。以上排除和限制同时适用于任何我们的供应商及分销商。</p>
            <p class="cdk_ServiceAgreement-txt1">11.2某些国家或司法管辖区不允许排除或限制某些默示保证，此时以上排除和限制将不适用于您。</p>
            <p class="cdk_ServiceAgreement-txt1">12. 责任范围</p>
            <p class="cdk_ServiceAgreement-txt1">12.1 如我们违约或有其他责任，您有权要求我们赔偿损失。在上述情形下，无论您基于何种理由要求我们赔偿损失（包括重大违约、过失、虚假陈述或其他合同或侵权方面的索赔），除根据可适用法律不能免除或限制的责任外，且仅在根据该法律不能免除或限制的范围内，我们的赔偿责任仅限于您遭受的实际直接损失，且最高不超过您为软件或服务所支付的价款。此限制不适用于我们应依法承担的人身伤害（包括死亡）、不动产和有形动产的损害赔偿责任。</p>
            <p class="cdk_ServiceAgreement-txt1">此限制同样适用于我们的供应商和销售商。这是我们及其供应商和销售商所承担责任总和的上限。</p>
            <p class="cdk_ServiceAgreement-txt1">12.2 在任何情况下，我们及其供应商或销售商对下列情形均不承担责任，即使已被告知该情形有可能发生：（1）第三方向您提出的索赔；（2）您的数据丢失或损坏；或（3）特殊的、附带的或间接的损害赔偿或任何后果性的经济损害赔偿，包括利润、业务收入、商誉或预期节余的损失。</p>
            <p class="cdk_ServiceAgreement-txt1">12.3 某些国家/地区或司法管辖区不允许对附带的或后果性的损害赔偿进行排除或限制，如果这样，则上述排除或限制可不适用于您。</p>
            <p class="cdk_ServiceAgreement-txt1">12.4 本协议项下软件和服务不适用于任何与原子能、航空、公共交通运输、医疗或任何其他具有内在危险性的应用有关的用途。此类应用可能会导致死亡、人身损害、事故或大规模的破坏。您同意，对将软件或服务用于此类用途导致的后果，我们将不承担任何性质的责任。</p>
            <p class="cdk_ServiceAgreement-txt1">13. 知识产权</p>
            <p class="cdk_ServiceAgreement-txt1">您知道并同意，我们或第三方拥有本软件或服务，或其任何部分，或通过或随同本软件或服务提供或获得的任何内容中含有的或与之有关的知识产权、所有权和权益。除非另有明确的许可，否则本软件和服务中含有的或与之相关的所有权利均由我们或第三方保留，我们未授予您任何默示的许可。</p>
            <p class="cdk_ServiceAgreement-txt1">我们将会根据著作权相关法律规定的流程，对著作权侵权通知进行处理，并采取措施屏蔽那些反复实施侵权行为的账户。如您认为某些人侵犯了您的著作权，您可按照我们版权政策声明的规定通知我们。</p>
            <p class="cdk_ServiceAgreement-txt1">14.营销材料和推广服务</p>
            <p class="cdk_ServiceAgreement-txt1">我们可能会不时向您发送一些营销材料以及为您提供附加的推广服务。您知道并同意，我们可以通过电子传输、电子邮件、信函或其他方式向您发送上述营销和推广材料。您了解并同意我们可能会不经另行通知随时调整我们提供上述推广服务的范围，某些推广服务可能仅针对新客户，您可能并不能获得某些或所有上述推广服务。如果你获得了我们提供的任何推广或特别价格或条件，这些价格或条件仅适用于您，您应对这些价格和条件保密，未经我们事先书面明确同意，您不得将此类信息披露给任何第三方。</p>
            <p class="cdk_ServiceAgreement-txt1">15.期限、终止和变更</p>
            <p class="cdk_ServiceAgreement-txt1">15.1 本协议在您接受后生效直至根据本协议规定终止。</p>
            <p class="cdk_ServiceAgreement-txt1">15.2 您可以通过停止使用本软件或服务，或关闭您的账户的方式随时终止本协议。</p>
            <p class="cdk_ServiceAgreement-txt1">15.3 如发现您正在为任何未经允许的或本协议禁止的用途使用软件或服务，我们可根据其判断终止本协议并就服务使用费及因您不当使用导致的损失向您索赔。</p>
            <p class="cdk_ServiceAgreement-txt1">15.4 您同意，本协议终止后，您使用软件和服务的权利将立即终止。</p>
            <p class="cdk_ServiceAgreement-txt1">15.5 本协议标题为“用户数据存储”、“知识产权”、“无保证声明”、“责任限制”及“其他”的条款在本协议到期或终止后继续有效。</p>
            <p class="cdk_ServiceAgreement-txt1">15.6 我们保留不时修改本协议（包括通用条款和特别条款）的权利，以反映相关法律及我们服务的变化。本协议经修改后，将在新用户接受后对新用户生效，对于原有用户，修订后的协议将在我们通知您后生效。但是，针对服务新功能的或基于法律原因所做的修改将立即生效。如果您不同意针对某项服务的修改后的条款，应停止使用该服务。</p>
            <p class="cdk_ServiceAgreement-txt1">16. 第三方软件和服务</p>
            <p class="cdk_ServiceAgreement-txt1">16.1 您了解并同意某些软件或服务可能由第三方根据其不同于本协议的独立条款提供。我们对第三方软件或服务不提供任何保证，第三方可能会就其软件或服务提供自己的保证。您理解并同意，您将基于自己的判断并自行承担风险通过本软件或服务下载或随同本软件或服务使用任何第三方软件或服务，并且，对因下载或使用第三方软件或服务所导致的任何责任，将依法律规定以及您与该第三方的法律条款承担，与我们无关。</p>
            <p class="cdk_ServiceAgreement-txt1">16.2 我们应用或服务用户界面上可能提供对第三方网站的链接或引用，您点击这些链接会进入非由我们运营、管理和支持的第三方网站。这些链接或引用仅为用户方便而设置，并不表明我们对这些网站及其内容作出了任何认可、推荐或保证。对这些第三方网站的服务及其内容，我们不做任何陈述和保证，包括但不限于其可获得性和持续性。</p>
            <p class="cdk_ServiceAgreement-txt1">16.3 您对第三方网站的使用可能受制于特定的法律条款并可能有许可及服务费产生。第三方网站的服务条款和隐私权政策可能与我们的规定有差异。您向这些第三方网站提交的或由其收集的您的个人信息，不适用我们的隐私权政策。如您选择使用该第三方网站，请您仔细阅读该第三方的相关服务条款和隐私权政策（如果有的话）。在进入或使用该第三方网站时，请您自行确定是否采取必要的防范措施，以免遭受病毒、蠕虫、木马和其他有害内容的侵害，我们不对这些网站的内容以及您对该网站的使用所导致的损害承担任何责任。</p>
            <p class="cdk_ServiceAgreement-txt1">17. 一般条款</p>
            <p class="cdk_ServiceAgreement-txt1">17.1 本协议依据购买地司法管辖区的法律制订和解释，且不考虑其冲突法规则。联合国国际货物销售合同公约不适用于本协议。</p>
            <p class="cdk_ServiceAgreement-txt1">17.2 我们未行使或强制执行包含在本协议中的任何权利或条款，不视为对相关权利和条款的放弃。如本协议的任何规定或部分被有管辖权的裁判机构判定为不可强制执行，不影响本协议的其他部分的效力，本协议其他部分仍继续有效。</p>
            <p class="cdk_ServiceAgreement-txt1">17.3 本协议结合我们隐私权政策构成您与我们之间就本协议标的达成的全部协议，并取代您与我们此前或同时就本协议标的达成的任何其他谅解或协议，无论是口头的还是书面的。任何对本协议任何条款的弃权只能以书面方式为之并经我们签署后方为有效。</p>
            <p class="cdk_ServiceAgreement-txt1">17.4 因本协议发生的任何争议，均应提交购买地司法管辖区的裁判机构裁决。但上述约定并不禁止我们向任何有管辖权的法院申请禁制令或其他保护其知识产权的强制措施。</p>
            <p class="cdk_ServiceAgreement-txt1">17.5 未经我们事先书面同意，您不得向第三方分派或转让您在本协议项下的任何权利。任何违反上述规定的分派或转让均属无效。我们可根据其业务模式安排关联机构或分包商执行本协议项下的部分或全部业务。</p>
            <p class="cdk_ServiceAgreement-txt1">喀什新巢电子科技服务有限公司</p>
        </div>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
// p{
//     margin: 0;
// }
.cdk_ServiceAgreement {
  width: 100%;
  height: 1199px;
  &-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    overflow: auto;
  }
  background-image: url("@/assets/img/cdk_exbg.png");
  background-repeat: no-repeat;
  background-size: 2560px 1199px;
  &-title {
    color: #fff;
    text-align: center;
    font-family: MiSans;
    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    padding: 80px 0 37px 0;
  }
  &-txtbox {
    width: 931px;
    height: 901px;

    margin: 0 auto;
    color: rgba(255, 255, 255, 0.6);
    font-family: MiSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
  }
  &-txt1 {
    color: rgba(255, 255, 255, 0.6);
    font-family: MiSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
  }
}
</style>