import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Http from "@/axios/http";
import '@/plugins/element-ui'
import {mixins} from '@/assets/js/minxin'
import { Message } from "element-ui";
Vue.mixin(mixins)
Vue.prototype.$message = Message

// 挂载axios
Vue.prototype.$Http = Http
console.log();
Vue.config.productionTip = false

// 设置title
router.beforeEach((to, from, next) => {
  if (to.meta.title) { 
    // 标题
    console.log("to.meta.title",to.meta.title);
    document.title = to.meta.title;
  }
  next();
});




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
