<template lang="">
    <div style="height:100%">
       <Cdk_Header />
       <router-view ></router-view >
       <Ckd_footer />
    </div>
</template>
<script>
import Cdk_Header from '@/components/Cdk_Header.vue'
import Ckd_footer from '@/components/Cdk_footer.vue'
export default {
    name:'Cdk_Wb',
    components:{
        Cdk_Header,
        Ckd_footer
    }
}
</script>
<style  lang="less" scoped>
    
</style>