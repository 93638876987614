<template lang="">
    <div class="ckd_header">
       <div class="ckd_header-box">
        <span class="cdk_logobox">
            <img src="@/assets/img/cdk_logo.svg" alt="">网游加速器
        </span>
        <router-link class="ckd_tab" to="/Cdk_page"   active-class="active" v-if="ckd_isHome">首页</router-link> 
        <router-link class="ckd_tab" to="/Cdk_exchange"   active-class="active">cdk兑换</router-link>
        <!-- <span class="ckd_tab"  @click="changeTab('/Cdk_page')"  :class="status == '/Cdk_page' ? 'active' :'' ">首页</span>
        <span class="ckd_tab" @click="changeTab('/Cdk_exchange')" :class="status == '/Cdk_exchange' ? 'active' :''">cdk兑换</span> -->
       </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      // 控制cdk官网和兑换页面
      ckd_isHome: 1,
      cdk_Isexchange: 2,
    };
  },
  created() {
    this.changeTab();
  },
  watch: {
    $router: {
      immediate: true,
      handler(to) {
        this.status = to.currentRoute.path;
      },
    },
  },
  methods: {
    //控制首页与兑换页
    changeTab() {
      this.setting().then(({data:{data}}) => {
        console.log("res",data[0].value);
        this.ckd_isHome = data[1].value;
        if ((this.$route.path == "/" || this.$route.path == '/Cdk_page') && this.ckd_isHome !== 1) {
          this.$router.push("/Cdk_exchange", () => {});
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ckd_header {
  width: 100%;
  height: 64px;
  background: #1c1c1c;
  &-box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    // justify-content: center;
    box-sizing: border-box;
    align-items: center;
    color: #fff;

    // padding-left: 680px;
    align-items: center;
  }
}
.cdk_logobox {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: MiSans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 41px;
  img {
    margin-right: 9px;
  }
}

.ckd_tab {
  position: relative;
  width: 110px;
  height: 64px;
  color: #8c8c8c;
  font-family: MiSans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  line-height: 64px;
  cursor: pointer;
  &.active {
    color: #62cb9b;
    font-family: MiSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 64px;
    &::after {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      content: "";
      display: block;
      width: 45px;
      height: 2px;
      background: #62cb9b;
    }
  }
}
.ckd_tab:hover {
  color: #fff;
}
</style>