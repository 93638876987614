<!--
 * @Author: pengrui
 * @Date: 2022-11-09 09:19:46
 * @LastEditors: pengrui
 * @LastEditTime: 2023-01-17 11:44:19
 * 使用方法
 * 引入组件<Geetest ref="Geetest" />
 *  ------------------
 *  var Geetest = this.$refs.Geetest;
 *  if (!Geetest.ready) return;
 *  let Geetestdata = await Geetest.show().catch(() => null);
 *  if (Geetestdata == null) return;
 *  ------------------
 *  Geetestdata为后端需求参数
-->
<style scoped lang="less">
.geet {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
}
</style>
<template>
  <div class="geet" id="CaptchaId"></div>
</template>
<script>
import  Geetest from "@/utils/gt";
export default {
  async created() {
    this.toolsSetting.is_off_geetest = 2;
    if (this.toolsSetting.is_off_geetest == 1) {
      this.init();
    } else {
      this.ready = true;
    }
  },
  data() {
    return {
      ready: false,
      box: null,
      toolsSetting: {},
    };
  },
  methods: {
    // async getTools() {
    //   return this.$services.toolsSetting({ key_name: "is_off_geetest" });
    // },
    // 极验证的
    init() {
      const _this = this;
      _this.ready = false;
      this.$services.getGT({ type: "web" }).then((data) => {
        Geetest.initGeetest(
          {
            // 以下配置参数来自服务端 SDK
            gt: data.gt,
            challenge: data.challenge,
            offline: !data.success,
            new_captcha: true,
            width: "100%",
            product: "bind",
            protocol: "https://",
            api_server: "api.geevisit.com",
            api_server_v3: ["api.geevisit.com", "api.geetest.com", "api.gsensebot.com"],
          },
          function (captchaObj) {
            _this.box = captchaObj;
            captchaObj
              .onReady(function () {
                _this.ready = true;
              })
              .onSuccess(function () {
                var result = captchaObj.getValidate();
                var obj = {
                  ...result,
                  server_status: data.server_status,
                };
                _this.$emit("success", obj);
                _this.onSuccess(obj);
                _this.init();
              })
              .onError(function (err) {
                _this.$emit("onError", err);
                _this.onError(err);
                _this.init();
              });
          }
        );
      });
    },
    // 腾讯验证
    init2() {
      console.log("33333333");
      try {
        console.log("33333333");
        // 生成一个验证码对象
        // CaptchaAppId：登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
        //callback：定义的回调函数
        let captcha = new TencentCaptcha("195788894", this.callback, {needFeedBack: false});
        // 调用方法，显示验证码
        captcha.show();
        console.log("33333");
      } catch (error) {
        console.log(error);
        // 加载异常，调用验证码js加载错误处理函数
        this.loadErrorCallback();
      }
    },
    //腾讯返回正常
    callback(res) {
      // 第一个参数传入回调结果，结果如下：
      // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
      // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
      // CaptchaAppId       String    验证码应用ID。
      // bizState    Any       自定义透传参数。
      // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
      console.log("callback:", res);

      // res（用户主动关闭验证码）= {ret: 2, ticket: null}
      // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
      // res（请求验证码发生错误，验证码自动返回terror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
      // 此处代码仅为验证结果的展示示例，真实业务接入，建议基于ticket和errorCode情况做不同的业务处理
      if (res.ret === 0 && !res.errorCode) {
        // 复制结果至剪切板
        this.onSuccess({
          rand_str: res.randstr,
          behavior_ticket: res.ticket,
        });
      }
    },
    //腾讯返回异常
    loadErrorCallback() {
      var appid = "195788894";
      // 生成容灾票据或自行做其它处理
      var ticket = "terror_1001_" + appid + "_" + Math.floor(new Date().getTime() / 1000);
      this.callback({
        ret: 0,
        randstr: "@" + Math.random().toString(36).substr(2),
        ticket: ticket,
        errorCode: 1001,
        errorMessage: "jsload_error",
      });
    },
    show() {
      if (this.__Cancel) {
        this.__Cancel("cancel");
        this.__Cancel = null;
      }
      if (this.toolsSetting.is_off_geetest == 1) {
        return new Promise((resolve, reject) => {
          this.__Resolve = resolve;
          this.__Cancel = reject;
          this.box.verify();
          console.log("1111");
        });
      } else if (this.toolsSetting.is_off_geetest == 2) {
        return new Promise((resolve, reject) => {
          this.__Resolve = resolve;
          this.__Cancel = reject;
          this.init2();
          console.log("1111222");
        });
      } else if (!this.toolsSetting.is_off_geetest) {
        return new Promise((resolve, reject) => {
          this.__Resolve = resolve;
          this.__Cancel = reject;
          this.onSuccess({});
          console.log("333");
        });
      }
    },
    onSuccess(result) {
      this?.__Resolve?.(result);
    },
    onError(err) {
      console.warn("Geetest", err);
      this.__Cancel(err);
    },
  },
};
</script>
